import React from 'react';
import {Helmet} from "react-helmet";
import Layout from '../components/layout';
import SEO from '../components/seo';

import '../styles/skills.css';

const Skills = () => (
  
  <Layout>
    <SEO
      title="Skills"
      keywords={[`Hashir`, `Ghouri`, `Portfolio`, `Skills`]}
    />
    <Helmet>
      <link rel="stylesheet" href="https://cdn.rawgit.com/konpa/devicon/df6431e323547add1b4cf45992913f15286456d3/devicon.min.css"/>
    </Helmet>
    
      <div className="full-container ">
          <h1 className="head">Stack</h1>
          <div className="flex mb-4 ss mt-10 icon-container">
            <div className="icon-container-item"><i className="devicon-html5-plain-wordmark colored"></i></div>
            <div className="icon-container-item"><i className="devicon-css3-plain-wordmark colored"></i></div>
            <div className="icon-container-item"><i className="devicon-javascript-plain colored"></i></div>
            <div className="icon-container-item"><i className="devicon-bootstrap-plain-wordmark colored"></i></div>
            <div className="icon-container-item"><i className="devicon-foundation-plain-wordmark colored"></i></div>
          </div>
          <div className="flex mb-4 ss icon-container">
            <div className="icon-container-item"><i className="devicon-github-plain-wordmark colored"></i></div>
            <div className="icon-container-item"><i className="devicon-sass-original colored"></i></div>
            <div className="icon-container-item"><i className="devicon-react-original-wordmark colored"></i></div>
            <div className="icon-container-item"><i className="devicon-express-original colored"></i></div>
            <div className="icon-container-item"><i className="devicon-nodejs-plain colored"></i></div>
          </div>
          <div className="flex mb-4 ss icon-container">
            <div className="icon-container-item"><i className="devicon-postgresql-plain-wordmark colored"></i></div>
            <div className="icon-container-item"><i className="devicon-google-plain-wordmark colored"></i></div>
            <div className="icon-container-item"><i className="devicon-git-plain-wordmark colored"></i></div>
            <div className="icon-container-item"><i className="devicon-webpack-plain-wordmark colored"></i></div>
            <div className="icon-container-item"><i className="devicon-trello-plain-wordmark colored"></i></div>
          </div>
          <div className="flex mb-4 ss icon-container">
            <div className="icon-container-item"><i className="devicon-windows8-original colored"></i></div>
            <div className="icon-container-item"><i className="devicon-wordpress-plain-wordmark colored"></i></div>
            <div className="icon-container-item"><i className="devicon-photoshop-line colored"></i></div>
            <div className="icon-container-item"><i className="devicon-chrome-plain-wordmark colored"></i></div>
            <div className="icon-container-item"><i className="devicon-heroku-plain-wordmark colored"></i></div>
          </div>         
      </div>
      
  </Layout>

);

export default Skills
